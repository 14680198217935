// import React, { useState, useEffect } from 'react'
// import "./styles/Contact.css"
// import styled from 'styled-components'
// import { MdLocationOn, MdPhone, MdEmail} from 'react-icons/md'
// import { ImTruck, ImPhoneHangUp } from 'react-icons/im'
// import { GiTruck, GiBulldozer, GiCargoCrate, GiCargoShip } from 'react-icons/gi'
// import { FaTruckMonster, FaCarSide, FaMobileAlt, FaFax} from 'react-icons/fa'


// import { FormattedMessage, injectIntl, intlShape } from 'react-intl'

// import Aos from 'aos'
// import 'aos/dist/aos.css'


// // import ImageOne from '../assets/images/test4.jpeg'


// const ContactForm = ({ intl }) => {

//   const [formState, setFormState] = useState({
//       name: "",
//       email: ""
//   })

//   const encode = (data) => {
//     return Object.keys(data)
//         .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//         .join("&");
//   }

//   const handleChange = e => {
//       setFormState({
//           ...formState,
//           [e.target.name]: e.target.value,
//       })
//   }

//   const handleSubmit = e => {
//     fetch("/", {
//         method: "POST",
//         headers: { "Content-Type": "application/x-www-form-urlencoded" },
//         body: encode({ "form-name": "contact", ...formState })
//       })
//         .then(() => alert("We received you message.\nWe will reply to you soon."))
//         .catch(error => alert(error));

//       e.preventDefault();
//   }

//   useEffect( () => {
//     Aos.init({})
//   }, [])

//   const placeholderName = intl.formatMessage({ id: 'YourName' });
//   const placeholderSubject = intl.formatMessage({ id: 'YourSubject' });
//   const placeholderEmail = intl.formatMessage({ id: 'YourEmail' });
//   const placeholderPhone = intl.formatMessage({ id: 'YourPhone' });
//   const placeholderMessage = intl.formatMessage({ id: 'YourMessage' });
  

//   return (
//       <>
//          <section id="contact-a" class="text-center py-3">
//              <div class="container" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
//                  <h2 class="section-title" data-aos="fade-up"
//                                     data-aos-delay="50"
//                                     data-aos-duration="1000"> <FormattedMessage id="ContactH1" /> 
//                                     <div class="bottom-line"></div></h2>
                 
//                  <p class="lead"> <FormattedMessage id="ContactH2" /> </p>
//                  <form onSubmit={handleSubmit} name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
//                      <div class="text-fields">

//                          <input type="hidden" name="form-name" value="contact" />

//                          <input type="text" name="name" class="text-input name-input" id="name" onChange={handleChange} value={formState.name} placeholder={placeholderName} />
//                          <input type="text" class="text-input subject-input" id="subject" onChange={handleChange} value={formState.subject} placeholder={placeholderSubject} />
//                          <input type="email" name="email" class="text-input email-input" id="email" placeholder={placeholderEmail} onChange={handleChange} value={formState.email} />
//                          <input type="text" class="text-input phone-input" id="phone" placeholder={placeholderPhone}onChange={handleChange} value={formState.phone} />
//                          <textarea class="text-input message-input" id="message" placeholder={placeholderMessage} onChange={handleChange} value={formState.message} ></textarea>
//                      </div>
//                      <button type="submit" class="btn-dark" > <FormattedMessage id="Submit" /> </button>
                     
//                  </form>
//              </div>
//          </section>

//          <section id="contact-b" class="py-3 bg-dark">
//            <div class="container">
//                <div class="contact-info">
//                    <div>
//                        <i class="fas fa-2x rc"><MdEmail/></i>
//                        {/* <h3>Email</h3> */}
//                       <a href="mailto:info@etihadshipping.de"> <p>info@etihadshipping.de</p> </a>
//                    </div>
//                    <div>
//                        <i class="fas fa-2x rc"><MdPhone/></i>
//                        {/* <h3>Phone</h3> */}
//                        <br/>
//                        <a href="tel:+4940730913970"> <p class="lr"> <ImPhoneHangUp /> : <FormattedMessage id="Tel"/> </p></a>
//                        <a href="tel:+4916099108419"> <p class="lr"> <FaMobileAlt /> : <FormattedMessage id="Mobile"/> </p></a>
//                        <a href="tel:+49407309139716"> <p class="lr"> <FaFax /> : <FormattedMessage id="Fax"/> </p></a>
//                    </div>
//                    <div>
//                        <i class="fas fa-2x rc"><MdLocationOn/></i>
//                        {/* <h3>Address</h3> */}
//                        <p>Etihad Shipping Germany</p>
//                        <a target="_blank" href="http://maps.google.com/?q=20539 Etihad Shipping Germany Gmbh, Billstraase 180, Hamburg, 20539"><p>Billstrasse 180</p></a>
//                        <p>20539 Hamburg</p>
//                    </div>
//                </div>
//            </div>

//          </section>

//          <section id="contact-c" class="bg-main py-4">
//              <div class="container">
//              {/* <h1> <FormattedMessage id="ContactH3" /> </h1> */}
//              <h1><ImTruck/>  <GiTruck/>  <FaTruckMonster/> <FaCarSide/> <GiBulldozer/> <GiCargoCrate/> <GiCargoShip/> </h1>
//              </div>
//          </section>


//       </>
//   )

// }

// ContactForm.propTypes = {
//     intl: intlShape.isRequired,
//   };

// export default injectIntl(ContactForm)


///////////////////////////////////////////////////////

import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import "./styles/Contact.css"
import styled from 'styled-components'
import { MdLocationOn, MdPhone, MdEmail} from 'react-icons/md'
import { ImTruck, ImPhoneHangUp } from 'react-icons/im'
import { GiTruck, GiBulldozer, GiCargoCrate, GiCargoShip } from 'react-icons/gi'
import { FaTruckMonster, FaCarSide, FaMobileAlt, FaFax} from 'react-icons/fa'
import { useForm, ValidationError } from '@formspree/react';
import { RiMailCheckLine } from 'react-icons/ri'


import { FormattedMessage, injectIntl, intlShape } from 'react-intl'

import Aos from 'aos'
import 'aos/dist/aos.css'


// import ImageOne from '../assets/images/test4.jpeg'


const ContactForm = ({ intl }) => {


//   function sendEmail(e) {
//     e.preventDefault();

//     // emailjs.sendForm('gmail', 'template_yht5znq', e.target, 'user_4fxpFQU5vyqLSom1f0qUg')
//     //   .then((result) => {
//     //       console.log(result.text);
//     //   }, (error) => {
//     //       console.log(error.text);
//     //   });
//       e.target.reset()
//   }

  useEffect( () => {
    Aos.init({})
  }, [])

  const [state, handleSubmit] = useForm("moqynkkq");
    if (state.succeeded) {
        return (
            <>
            <section id="contact-a" class="text-center py-3">
             <div class="container" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
             <p class="lead"> <FormattedMessage id="ContactH2" /> </p>
             <p class="lead2"> <FormattedMessage id="ContactH22" /> </p>
             <p class="lead3"> <RiMailCheckLine /> </p>
             {/* <Btn round small primary to='/contact'> Go Home </Btn> */}

             </div>
            </section>
            <section id="contact-b" class="py-3 bg-dark">
           <div class="container">
               <div class="contact-info">
                   <div>
                       <i class="fas fa-2x rc"><MdEmail/></i>
                       {/* <h3>Email</h3> */}
                      <a href="mailto:info@etihadshipping.de"> <p>info@etihadshipping.de</p> </a>
                   </div>
                   <div>
                       <i class="fas fa-2x rc"><MdPhone/></i>
                       {/* <h3>Phone</h3> */}
                       <br/>
                       <a href="tel:+4940730913970"> <p class="lr"> <ImPhoneHangUp /> : <FormattedMessage id="Tel"/> </p></a>
                       <a href="tel:+4916099108419"> <p class="lr"> <FaMobileAlt /> : <FormattedMessage id="Mobile"/> </p></a>
                       <a href="tel:+49407309139716"> <p class="lr"> <FaFax /> : <FormattedMessage id="Fax"/> </p></a>
                   </div>
                   <div>
                       <i class="fas fa-2x rc"><MdLocationOn/></i>
                       {/* <h3>Address</h3> */}
                       <a target="_blank" href="http://maps.google.com/?q=20539 Etihad Shipping Germany Gmbh, Billstraase 180, Hamburg, 20539"> <p>Etihad Shipping Germany</p> </a>
                       <p>Billstrasse 180</p>
                       <p>20539 Hamburg</p>
                   </div>
               </div>
           </div>

         </section>

         <section id="contact-c" class="bg-main py-4">
             <div class="container">
             {/* <h1> <FormattedMessage id="ContactH3" /> </h1> */}
             <h1><ImTruck/>  <GiTruck/>  <FaTruckMonster/> <FaCarSide/> <GiBulldozer/> <GiCargoCrate/> <GiCargoShip/> </h1>
             </div>
         </section>
         </>
            
        );
    }

  const placeholderName = intl.formatMessage({ id: 'YourName' });
  const placeholderSubject = intl.formatMessage({ id: 'YourSubject' });
  const placeholderEmail = intl.formatMessage({ id: 'YourEmail' });
  const placeholderPhone = intl.formatMessage({ id: 'YourPhone' });
  const placeholderMessage = intl.formatMessage({ id: 'YourMessage' });
  

  return (
      <>
         <section id="contact-a" class="text-center py-3">
             <div class="container" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
                 <h2 class="section-title" data-aos="fade-up"
                                    data-aos-delay="50"
                                    data-aos-duration="1000"> <FormattedMessage id="ContactH1" /> 
                                    <div class="bottom-line"></div></h2>
                 
                 {/* <p class="lead"> <FormattedMessage id="ContactH2" /> </p> */}
                 <form name="contact" onSubmit={handleSubmit} data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
                     <div class="text-fields">

                         <input type="hidden" name="form-name" value="contact" />

                         <input type="text" name="name" class="text-input name-input" id="name" placeholder={placeholderName} />
                         <input type="text" name="subject" class="text-input subject-input" id="subject" placeholder={placeholderSubject} />
                         <input type="email" name="_replyto" class="text-input email-input" id="email" placeholder={placeholderEmail} />
                         <input type="text" class="text-input phone-input" name="phone" id="phone" placeholder={placeholderPhone} />
                         <textarea class="text-input message-input" name="message" id="message" placeholder={placeholderMessage}  ></textarea>
                     </div>
                     <button type="submit" class="btn-dark" disabled={state.submitting}> <FormattedMessage id="Submit" /> </button>
                     
                 </form>
             </div>
         </section>

         <section id="contact-b" class="py-3 bg-dark">
           <div class="container">
               <div class="contact-info">
                   <div>
                       <i class="fas fa-2x rc"><MdEmail/></i>
                       {/* <h3>Email</h3> */}
                      <a href="mailto:info@etihadshipping.de"> <p>info@etihadshipping.de</p> </a>
                   </div>
                   <div>
                       <i class="fas fa-2x rc"><MdPhone/></i>
                       {/* <h3>Phone</h3> */}
                       <br/>
                       <a href="tel:+4940730913970"> <p class="lr"> <ImPhoneHangUp /> : <FormattedMessage id="Tel"/> </p></a>
                       <a href="tel:+4916099108419"> <p class="lr"> <FaMobileAlt /> : <FormattedMessage id="Mobile"/> </p></a>
                       <a href="tel:+49407309139716"> <p class="lr"> <FaFax /> : <FormattedMessage id="Fax"/> </p></a>
                   </div>
                   <div>
                       <i class="fas fa-2x rc"><MdLocationOn/></i>
                       {/* <h3>Address</h3> */}
                       <a target="_blank" href="http://maps.google.com/?q=20539 Etihad Shipping Germany Gmbh, Billstraase 180, Hamburg, 20539"> <p>Etihad Shipping Germany</p> </a>
                       <p>Billstrasse 180</p>
                       <p>20539 Hamburg</p>
                   </div>
               </div>
           </div>

         </section>

         <section id="contact-c" class="bg-main py-4">
             <div class="container">
             {/* <h1> <FormattedMessage id="ContactH3" /> </h1> */}
             <h1><ImTruck/>  <GiTruck/>  <FaTruckMonster/> <FaCarSide/> <GiBulldozer/> <GiCargoCrate/> <GiCargoShip/> </h1>
             </div>
         </section>


      </>
  )

}

ContactForm.propTypes = {
    intl: intlShape.isRequired,
  };

const Btn = styled(Link)`
    background: ${({ primary }) => (primary ? '#bd0b27' : '#f8f8f8')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '16px 40px' : '10px 32px')};
    font-size: ${({ big }) => (big ? '20px' : '16px')};
    color: white;
    outline: none;
    margin-bottom: 3rem;
    border: none;
    text-decoration: none;
    min-width: 100px;
    cursor: pointer;
    transition: 0.3s !important;
    border-radius: ${({ round }) => (round ? '60px' : 'none')};
    

    &:hover {
        background: ${({ primary }) => (primary ? '#1a2b3d' : '#bd0b27')};
        color: #fff;
    }
    
`

export default injectIntl(ContactForm)