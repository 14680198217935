import React from "react"
import { FormattedMessage } from 'react-intl'
import { Layout } from '../components/common/Layout'
import Head from '../components/common/Head'
import SEO from "../components/seo"
import Explain from "../components/Explain"
import ContactForm from "../components/ContactForm"
import Scroll from "../components/Scroll"
import "../components/layout.css"

const Contact = () => (
  <Layout>
    <Scroll showBelow={250} />
    <Head title="ContactPageTitle" />
    <h1 className="contactUs"><FormattedMessage id="ContactUs" /></h1>
    <ContactForm />
  </Layout>
)

export default Contact