import React, { useEffect } from 'react'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Explain = ({heading, paragraphOne, reverse, image}) => {
    
    useEffect( () => {
        Aos.init({})
      }, [])

    return (

        <Section>
            <Container reverse={reverse}>
                <ColumnLeft reverse={reverse} data-aos="fade-down"
          data-aos-delay="100"
          data-aos-duration="1000">
                <h1>{heading}</h1>
                <BottomLine/>
                <p>{paragraphOne}</p>
                </ColumnLeft>
                <ColumnRight reverse={reverse} data-aos="fade-up"
          data-aos-delay="150"
          data-aos-duration="1000">
                <img src={image} reverse={reverse} alt="home"/>
                </ColumnRight>
            </Container>
        </Section>
    )
}

export default Explain

const Section = styled.section`
    width: 100%;
    height: 100%;  
`

const Container = styled.div`
    /* padding: 0rem calc((100vw - 1300px) / 2); */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px;
    background: ${({reverse}) => (reverse ? '#edf2f5' : '#dbe2e7')};   
    color: #283951; 
    padding: 0 0;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

const ColumnLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1.4;
    padding: ${({reverse}) => (reverse ? '1rem 4rem 1rem 3rem' : '1rem 3rem 1rem 4rem')};
    order: ${({reverse}) => (reverse ? '2' : '1')};

    h1 {
        margin-bottom: 1rem;
        font-size: clamp(1.4rem, 6vw, 0.6rem);
        font-weight: bold;
        color: #bd0b27;
        text-transform: uppercase;
    }

    p {
        font-size: clamp(1.1rem, 6vw, 0.4rem);
       
    }

    @media screen and (max-width: 768px) {
        padding: 1 2rem;
    }
`

const ColumnRight = styled.div`
    padding: 0rem 0;
    order: ${({reverse}) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        order: ${({reverse}) => (reverse ? '2' : '1')};
        padding: 0 2rem;
    }

    img {
        width: 86%;
        height: 86%;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            width: 90%;
            height: 90%;
            /* display: none; */
    }
    }
`

const BottomLine = styled.div`
    height: 5px;
    width: 100%;
    display: block;
    background-color: #bd0b27;
    margin: 0 auto 2rem 0;

    
    @media screen and (max-width: 1020px) {
            display:none;
    }

`